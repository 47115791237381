import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-teacher/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  props: {},
  components: {},
  setup: function setup() {
    var dataMap = reactive({});
    var refData = toRefs(dataMap);
    return _objectSpread({}, refData);
  }
});