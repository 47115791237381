import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

_pushScopeId("data-v-73cdbf23");

var _hoisted_1 = {
  class: "innerCircle"
};
var _hoisted_2 = {
  ref: "canvas",
  id: "canvas"
};

_popScopeId();

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("video", {
    autoplay: "",
    ref: "video",
    id: "video",
    "webkit-playsinline": "true",
    playsinline: "true",
    style: _normalizeStyle(_ctx.videoStyle)
  }, null, 4)]), _createElementVNode("canvas", _hoisted_2, null, 512)], 64);
}