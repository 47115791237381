import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue";
import _imports_0 from '@/assets/img/student/reverseCamera.png';
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_cameraFace = _resolveComponent("cameraFace");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_cameraFace, {
    ref: "camera",
    onLoading: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.logEvent('loading');
    }),
    onStarted: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.logEvent('started');
    }),
    autoplay: ""
  }, null, 512), _createElementVNode("img", {
    src: _imports_0,
    class: "reverseCamera",
    onClick: _cache[2] || (_cache[2] = function () {
      return _ctx.handleReverseCamera && _ctx.handleReverseCamera.apply(_ctx, arguments);
    })
  })], 64);
}